import React, { useState } from "react";
import { Row, Col, Form, Input, Card, Divider, Button, message } from "antd";
import { FV, PV, PMT } from "../utils/ExcelFormula";

const PentionCalculate = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [formAge] = Form.useForm();
  const [formExpense] = Form.useForm();
  const [formInvestment] = Form.useForm();
  const [formPension] = Form.useForm();

  const [yearsBeforeRetairment, setYearsBeforeRetairment] = useState(0);
  const [yearsOfRetirement, setYearsOfRetirement] = useState(0);
  const [fvVar, setFvVar] = useState(0);
  const [retirementYearMoney, setRetirementYearMoney] = useState(0);
  const [retirementTotal, setRetirementTotal] = useState(0);
  const [preparedPension, setPreparedPension] = useState(0);
  const [pensionGap, setPensionGap] = useState(0);
  const [pensionReadyYear, setPensionReadyYear] = useState(0);
  const [pensionReadyOnce, setPensionRdadyOnce] = useState(0);

  const reqTag = false;

  const buttonHandler = () => {
    const current_age = formAge.getFieldValue("current_age"); //目前年齡
    const retirement_age = formAge.getFieldValue("retirement_age"); //退休年齡
    const expected_age = formAge.getFieldValue("expected_age"); //預期壽命

    if (!(expected_age > retirement_age && retirement_age > current_age)) {
      messageApi.error("年齡設定錯誤");
    } else {
      let expense_monthly = formExpense.getFieldValue("monthly"); //預估退休後每月生活支出
      expense_monthly = expense_monthly * 10000;
      let expense_onetime = formExpense.getFieldValue("onetime"); //退休後需準備之整筆開銷
      expense_onetime = expense_onetime * 10000;
      let expense_estimated_inflation = formExpense.getFieldValue("estimated_inflation"); //預估通貨膨脹率
      expense_estimated_inflation = expense_estimated_inflation * 0.01;

      let investment_onetime = formInvestment.getFieldValue("onetime"); //預計退休前現有可單筆投入退休準備的資金
      investment_onetime = investment_onetime * 10000;
      let investment_monthly = formInvestment.getFieldValue("monthly"); //預計退休前現有每個月投入退休準備的資金
      investment_monthly = investment_monthly;
      let investment_estimated_ROI_before = formInvestment.getFieldValue("estimated_ROI_before"); //預估退休前的投資報酬率
      investment_estimated_ROI_before = investment_estimated_ROI_before * 0.01;
      let investment_estimated_ROI_after = formInvestment.getFieldValue("estimated_ROI_after"); //預估退休後的投資報酬率
      investment_estimated_ROI_after = investment_estimated_ROI_after * 0.01;

      let pension_monthly = formPension.getFieldValue("monthly"); //預計退休後可請領每月年金
      pension_monthly = pension_monthly * 10000;
      let pension_onetime = formPension.getFieldValue("onetime"); //預估退休後可領的單筆退休金
      pension_onetime = pension_onetime * 10000;

      // ------- 計算 -----------

      setYearsBeforeRetairment(retirement_age - current_age); // 還有幾年退休
      setYearsOfRetirement(expected_age - retirement_age); // 退休後有幾年
      const firstYearExpense = FV(
        expense_estimated_inflation,
        retirement_age - current_age,
        0,
        -1 * expense_monthly,
        0
      );
      setFvVar(firstYearExpense); // 退休後第一年每月費用

      // 計算退休當年開銷
      let rMoney = 0;
      if (investment_estimated_ROI_after == expense_estimated_inflation) {
        rMoney = yearsOfRetirement * (fvVar - pension_monthly) * 12 * 10000;
        setRetirementYearMoney(rMoney);
      } else {
        rMoney =
          (((firstYearExpense / 10000 - pension_monthly / 10000) * 12) /
            (investment_estimated_ROI_after - expense_estimated_inflation)) *
          (1 -
            Math.pow(
              (1 + expense_estimated_inflation) / (1 + investment_estimated_ROI_after),
              expected_age - retirement_age
            )) *
          (1 + investment_estimated_ROI_after) *
          10000;
        setRetirementYearMoney(rMoney);
      }

      // 計算退休總後所需準備的退休金
      setRetirementTotal(rMoney + expense_onetime);

      // 計算退休當年已準備的退休金
      let pMoney =
        FV(
          investment_estimated_ROI_before,
          retirement_age - current_age,
          -1 * investment_monthly * 12,
          -1 * investment_onetime,
          0
        ) + pension_onetime;
      setPreparedPension(pMoney);

      // 計算資金缺口
      setPensionGap(rMoney + expense_onetime - pMoney);

      // 計算每年需投入準備金額
      setPensionReadyYear(
        -1 * PMT(investment_estimated_ROI_before, retirement_age - current_age, 0, rMoney + expense_onetime - pMoney)
      );

      // 計算單筆需投入金額
      setPensionRdadyOnce(
        -1 * PV(investment_estimated_ROI_before, retirement_age - current_age, 0, rMoney + expense_onetime - pMoney)
      );
    }
  };

  return (
    <>
      {contextHolder}
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
          <div style={{ backgroundColor: "#e3dede", borderRadius: "10px", padding: "1px 10px", marginTop: "10px" }}>
            <h3>退休金試算</h3>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
          <Form form={formAge} requiredMark={false} layout="vertical" size="middle">
            <Card
              size="small"
              title="年齡資料"
              hoverable={true}
              headStyle={{ backgroundColor: "#6E7783", color: "#ffffff" }}
              style={{ borderColor: "#6E7783" }}
            >
              <Form.Item required={reqTag} name="current_age" label="目前年齡">
                <Input
                  placeholder="目前年齡"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item required={reqTag} name="retirement_age" label="退休年齡">
                <Input
                  placeholder="退休年齡"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item required={reqTag} name="expected_age" label="預期壽命">
                <Input
                  placeholder="預期壽命"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Card>
          </Form>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
          <Form form={formExpense} requiredMark={false} layout="vertical" size="middle">
            <Card
              title="支出"
              size="small"
              hoverable={true}
              headStyle={{ backgroundColor: "#D8E6E7", color: "#000000" }}
              style={{ borderColor: "#D8E6E7" }}
              // bodyStyle={{ backgroundColor: "#FFFFF3" }}
            >
              <Form.Item required={reqTag} name="monthly" label="預估退休後每月生活支出">
                <Input
                  placeholder=""
                  suffix="萬"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item required={reqTag} name="onetime" label="退休後需準備之整筆開銷">
                <Input
                  placeholder=""
                  suffix="萬"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item required={reqTag} name="estimated_inflation" label="預估通貨膨脹率">
                <Input
                  placeholder=""
                  suffix="%"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Card>
          </Form>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
          <Form form={formInvestment} requiredMark={false} layout="vertical" size="middle">
            <Card
              title="投資"
              size="small"
              hoverable={true}
              headStyle={{ backgroundColor: "#9DC3C1", color: "#ffffff" }}
              style={{ borderColor: "#9DC3C1" }}
              // bodyStyle={{ backgroundColor: "#FFFFF3" }}
            >
              <Form.Item required={reqTag} name="onetime" label="預計退休前現有可單筆投入退休準備的資金">
                <Input
                  placeholder=""
                  suffix="萬"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item required={reqTag} name="monthly" label="預計退休前現有每個月投入退休準備的資金">
                <Input
                  placeholder=""
                  suffix="元"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item required={reqTag} name="estimated_ROI_before" label="預估退休前的投資報酬率">
                <Input
                  placeholder=""
                  suffix="%"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item required={reqTag} name="estimated_ROI_after" label="預估退休後的投資報酬率">
                <Input
                  placeholder=""
                  suffix="%"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Card>
          </Form>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
          <Form form={formPension} requiredMark={false} layout="vertical" size="middle">
            <Card
              title="退休金"
              size="small"
              hoverable={true}
              headStyle={{ backgroundColor: "#77AAAD", color: "#ffffff" }}
              style={{ borderColor: "#77AAAD" }}
              // bodyStyle={{ backgroundColor: "#FFFFF3" }}
            >
              <Form.Item required={reqTag} name="monthly" label="預計退休後可請領每月年金">
                <Input
                  placeholder=""
                  suffix="萬"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item required={reqTag} name="onetime" label="預估退休後可領的單筆退休金">
                <Input
                  placeholder=""
                  suffix="萬"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Card>
          </Form>
        </Col>
      </Row>

      <Divider plain style={{ borderColor: "darkgray" }}>
        <Button
          type="primary"
          size="large"
          style={{ backgroundColor: "darkblue", width: "170px" }}
          onClick={buttonHandler}
        >
          開始試算
        </Button>
      </Divider>

      <Row gutter={[10, 10]}>
        <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
          <div style={{ backgroundColor: "#dae9f4", borderRadius: "10px", padding: "1px 10px", marginTop: "10px" }}>
            <h3>計算結果</h3>
          </div>
        </Col>

        <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
          <Card
            size="small"
            title="退休時間表"
            hoverable={true}
            headStyle={{ backgroundColor: "#6E7783", color: "#ffffff" }}
            style={{ borderColor: "#6E7783" }}
          >
            <h4>
              預計<b style={{ fontSize: "1.3rem", color: "red" }}> {yearsBeforeRetairment} </b>年之後開始退休
            </h4>
            <h4>
              退休生活持續<b style={{ fontSize: "1.3rem", color: "red" }}> {yearsOfRetirement} </b>年
            </h4>
            <h4>
              有<b style={{ fontSize: "1.3rem", color: "red" }}> {yearsBeforeRetairment} </b>年時間準備退休金
            </h4>
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
          <Card
            title="退休費用"
            size="small"
            hoverable={true}
            headStyle={{ backgroundColor: "#D8E6E7", color: "#000000" }}
            style={{ borderColor: "#D8E6E7" }}
          >
            <h4>
              退休開始<b style={{ fontSize: "1.1rem", color: "blue" }}>第一年</b>的每個月生活支出
              <b style={{ fontSize: "1.3rem", color: "red" }}> {(fvVar / 10000).toFixed(2)} </b>萬
            </h4>
            <h4>
              退休當年總共需準備生活開銷金額
              <b style={{ fontSize: "1.3rem", color: "red" }}> {(retirementYearMoney / 10000).toFixed(2)} </b>萬
            </h4>
            <h4>
              總計所需準備之退休金
              <b style={{ fontSize: "1.3rem", color: "red" }}> {(retirementTotal / 10000).toFixed(2)} </b>萬
            </h4>
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
          <Card
            title="退休資金缺口"
            size="small"
            hoverable={true}
            headStyle={{ backgroundColor: "#9DC3C1", color: "#ffffff" }}
            style={{ borderColor: "#9DC3C1" }}
          >
            <h4>
              退休當年已準備退休金
              <b style={{ fontSize: "1.3rem", color: "red" }}> {(preparedPension / 10000).toFixed(2)} </b>萬
            </h4>
            <h4>
              退休金缺口
              <b style={{ fontSize: "1.3rem", color: "red" }}>
                {" "}
                {pensionGap > 0 ? (pensionGap / 10000).toFixed(2) : 0}{" "}
              </b>
              萬
            </h4>
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
          <Card
            title="需投入金額"
            size="small"
            hoverable={true}
            headStyle={{ backgroundColor: "#77AAAD", color: "#ffffff" }}
            style={{ borderColor: "#77AAAD" }}
          >
            <h4>
              每年需投入退休準備金額
              <b style={{ fontSize: "1.3rem", color: "red" }}>
                {" "}
                {pensionReadyYear > 0 ? (pensionReadyYear / 10000).toFixed(2) : 0}{" "}
              </b>
              萬
            </h4>
            <h4>
              每月需投入退休準備金額{" "}
              <b style={{ fontSize: "1.3rem", color: "red" }}>
                {" "}
                {pensionReadyYear > 0 ? (pensionReadyYear / 12 / 10000).toFixed(2) : 0}{" "}
              </b>
              萬
            </h4>
            <h4>
              單筆需投入金額
              <b style={{ fontSize: "1.3rem", color: "red" }}>
                {" "}
                {pensionReadyOnce > 0 ? (pensionReadyOnce / 10000).toFixed(2) : 0}{" "}
              </b>
              萬
            </h4>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PentionCalculate;
