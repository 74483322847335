import { Row, Col } from "antd";
import PentionCalculate from "./components/PentionCalculate";

function App() {
  return (
    <Row>
      <Col
        xs={{ offset: 0, span: 24 }}
        sm={{ offset: 0, span: 24 }}
        md={{ offset: 0, span: 24 }}
        lg={{ offset: 0, span: 24 }}
        xl={{ offset: 2, span: 20 }}
        xxl={{ offset: 2, span: 20 }}
      >
        <PentionCalculate />
      </Col>
    </Row>
  );
}

export default App;
