// Excel FV function
export function FV(rate, nper, pmt, pv, type) {
  var pow = Math.pow(1 + rate, nper),
    fv;

  pv = pv || 0;
  type = type || 0;

  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv;
}

// Excel PV function
export function PV(rate, periods, payment, future, type) {
  // Initialize type
  var type = typeof type === "undefined" ? 0 : type;

  // Evaluate rate and periods (TODO: replace with secure expression evaluator)
  rate = eval(rate);
  periods = eval(periods);

  // Return present value
  if (rate === 0) {
    return -payment * periods - future;
  } else {
    return (
      (((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate * type) - future) / Math.pow(1 + rate, periods)
    );
  }
}

// Excel PMT function
export const PMT = (rate, nper, pv, fv, type) => {
  /*
   * rate   - interest rate per month
   * nper   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  let pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (rate === 0) return -(pv + fv) / nper;

  pvif = Math.pow(1 + rate, nper);
  pmt = (-rate * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + rate;
  return pmt;
};
